import image1 from "../img/Cheremina.png";
import image2 from "../img/Altukhov.png";
import image3 from "../img/Danshina.png";
import image4 from "../img/Tretyakov.png";
import image5 from "../img/Expert.png";
import imageOne from "../img/Cheremina2.png";
import imageSecond from "../img/Altukhov2.png";
import imageThird from "../img/Danshina2.png";
import imageFourth from "../img/Tretyakov2.png";
import imageFifth from "../img/Lebedev2.png";


export const expertsList = [
    {
        id: 1,
        name: "Ольга Черемина",
        img: image1,
        imgNew: imageOne,
        about: ["Директор по развитию бизнеса Avon",  "Эксперт по стратегии и трансформации бизнеса (ex-big 4 (KMPG, Deloitte))", "Магистратура Warwick business school (Стратегия и менеджмент)"],
    },

    {
        id: 2,
        name: "Алексей Алтухов",
        img: image2,
        imgNew: imageSecond,
        about: ["Эксперт в области Commercial/Business Excellence с более чем 18-ти летним опытом в фарм-бизнесе", "⁠Лидер с опытом руководства большими командами ведущих профессионалов в международных фармацевтических компаниях: Sanofi, Abbott и Ipsen в России, СНГ, Бразилии, Израиле и Европе", "Executive MBA в области General Management Стокгольмской Школы Экономики"],
    },

    {
        id: 3,
        name: "Анастасия Даньшина",
        img: image3,
        imgNew: imageThird,
        dscr: "Член Гильдии Маркетологов России, сертифицированный эксперт по маркетингу. Квалификация в направлении «Маркетинговые исследования» присуждена Экспертным Советом Гильдии маркетологов в 2023 году.",
        about: ["Разработка маркетинговых стратегий для крупных международных компаний", "E-commerce для B2B продуктов"],
    },

    {
        id: 4,
        name: "Ярослав Третьяков",
        img: image4,
        imgNew: imageFourth,
        about: ["Основатель digital-агенства MedGuru", "Эксперт в обалсти создания архитектуры облачных решений для фармацевтического бизнеса"],
    },

    {
        id: 5,
        name: "Георгий Лебедев",
        img: image5,
        imgNew: imageFifth,
        about: ["Директор центра цифровой медицины, заведующий кафедрой информационных технологий и обработки медицинских данных Сеченовского университета, д.т.н."],
    },
]