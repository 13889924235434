import React from 'react';
import './CSS/main.css';
import Header from './components/header';
import Hero from './components/Hero';
import Autoline from './components/Autoline';
import About from './components/About';
import Experts from './components/Experts';
import Ruby from './components/Ruby';
import Awards from './components/Awards';
import Participants from './components/Participants';
import Form from "./components/Form";
import Footer from './components/Footer';

function App() {
  const scrollToElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  
  return (
    <div className="App">
      <Header scrollToElement={scrollToElement}/>
      <Hero />
      <Autoline />
      <div className='adress-container container flex'>
        <h2 className="section-title">адрес проведения мероприятия</h2>
        <p>Панорамный лофт на крыше универмага «Цветной». Адрес: Цветной б-р, дом 15, строение 1, этаж 7, Москва.</p>
        <ul className='adress-list list-reset flex'>
          <li><b>Гостевой вход:</b> расположен в правом торце Универмага.</li>
          <li><b>На автомобиле:</b> съезд с Цветного бульвара направо, сразу после здания метро (переулок между зданием метро и Универмагом).</li>
        </ul>
      </div>
      <Form />
      <About />
      <Experts />
      <Ruby />
      <Awards />
      <Participants />
      <Footer />
    </div>
  );
}

export default App;
