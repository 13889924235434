import React from 'react'

export default function Autoline() {
  return (
    <div className="autoline flex">
        <div className="autoline-txt">
            12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;| 12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;|12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;| 12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;| 12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;| 12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;| 12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;| 12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;| 12 сентября 2024&nbsp;| Ruby Cloud Masters&nbsp;|
        </div>
    </div>
  )
}
